body {
	font-family: "museo-sans", sans-serif;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "museo-sans", sans-serif;
}

a,
button {
	transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
}

.container {
	max-width: 1170px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
}

.container-fluid {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.villanova_pager {}

.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 7px 0 0;
	border-radius: 4px;
}

.pagination li {
	display: inline;
}

.pagination a {
	color: #000;
	float: left;
	padding: 4px 10px;
	transition: background-color .3s;
	border: 1px solid #ddd;
	margin: 0 4px;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	margin-left: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.pagination a.active,
.pagination li.active a {
	background-color: #016aab;
	color: #fff;
	border: 1px solid #016aab;
}

.pagination>li>a,
.pagination>li>span {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: #337ab7;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #ddd;
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
	color: #777;
	cursor: not-allowed;
	background-color: #fff;
	border-color: #ddd;
}

/*popup css*/
.popup-model .MuiDialog-scrollPaper {
	display: block;
}

.popup-model .MuiDialog-paperWidthSm {
	width: 80%;
	margin: 30px auto;
	background: transparent;
}

.popup-model .model-content {
	position: relative;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 6px;
	outline: 0;
	-webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
	box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
}

.popup-model .modal-header {
	border-bottom: 1px solid #e5e5e5;
	padding: 15px;
}

.popup-model .modal-header button {
	opacity: .2;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	-webkit-appearance: none;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0;
	margin-top: -2px;
	font-weight: 700;
	float: right;
	font-size: 18px;
	font-family: "museo-sans", sans-serif;
}

.popup-model .modal-header button:hover {
	opacity: 0.5;
}

.popup-model .modal-header h3 {
	line-height: 1.42857143;
	font-weight: normal;
	font-family: MuseoSans-500;
	
	font-size: 20px;
	margin: 0px;
}

.popup-model .modal-header h3.popup-title {
	color: #0069aa;
	font-size: 18px;
	line-height: 1.42857143;
}

.popup-model .model-body {
	padding: 15px;
	position: relative;
}

.popup-model .model-body p {
	font-size: 14px;
	line-height: 1.42857143;
	color: #333;
	margin: 0px;
}

.input-div {
	margin-bottom: 20px;
}

.input-div label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	color: #333;
	font-weight: 700;
	font-size: 14px;
	line-height: 1.42857143;
}

.input-div input,
.input-div .MuiSelect-select {
	color: #555;
	font-size: 14px;
	line-height: 1.42857143;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.input-div input:focus,
.input-div .MuiSelect-select:focus,
.input-div textarea:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.input-div textarea {
	color: #555;
	font-size: 14px;
	line-height: 1.42857143;
	width: calc(100% - 24px);
	padding: 6px 12px;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.input-div div:before {
	display: none;
}

.input-div div:after {
	display: none;
}

.model-footer {
	text-align: right;
	border-top: 1px solid #e5e5e5;
}

.model-footer button {
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	white-space: nowrap;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
	text-align: center;
	-webkit-transition: all .5s ease 0s;
	-moz-transition: all .5s ease 0s;
}

.model-footer button:last-child {
	margin-bottom: 0;
	margin-left: 5px;
}

.model-footer .close-btn {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}

.model-footer .close-btn:hover {
	color: #333;
	background-color: #e6e6e6;
	border-color: #adadad;
}

.model-footer .delete-btn {
	background-color: #84a84d;
	color: #fff;
	border-color: #4cae4c;
}

.model-footer .delete-btn:hover {
	background-color: #759840;
	border-color: #398439;
	color: #fff;
}

.model-footer .delete-btn {
	background-color: #036;
	border-color: #036;
	padding: 5px 14px;
	box-shadow: none;
}

.model-footer .delete-btn:hover {
	background-color: #10273e;
	border-color: #10273e;
}

.model-footer .delete-btn-light {
	background-color: #006AAA;
	border-color: #006AAA;
	margin-left: 2px;
	color: #fff;
}

.model-footer .delete-btn-light:hover {
	background-color: #006AAA;
	border-color: #006AAA;
	color: #fff;
}

.popup-model .modal-header .text-light {
	color: #333;
}

.popup-model-new .input-div label {
	display: flex;
	align-items: center;
	font-family: MuseoSans-700;
	font-weight: normal;
}

.popup-model-new .input-div label .tool-info {
	display: inline-block;
	padding-left: 5px;
	line-height: 0px;
	margin: 9px 1px;
}

.popup-model-new .input-div label .tool-info img {
	margin-top: 0;
	max-width: 20px;
	cursor: pointer;
}

.popup-model-new.popup-model .model-body {
	padding-bottom: 0px;
}

.popup-model-new .model-footer {
	border-top: 0px;
	padding-top: 0px;
}

.popup-model-new .model-footer button {
	text-transform: uppercase;
}

.popup-model-new .model-footer button.text-capitalize {
	text-transform: initial;
	font-family: MuseoSans-100;
	font-weight: normal;
}

.dashboard-page .MuiTabs-flexContainer {
	margin-bottom: 8px;
	border-bottom: 1px solid #ddd;
}

.dashboard-page .MuiButtonBase-root {
	margin-right: 2px;
	line-height: 1.42857143;
	border: 1px solid transparent;
	border-radius: 4px 4px 0 0;
	display: block;
	border: 1px solid transparent;
	margin-bottom: -1px;
	font-size: 14px;
	min-width: initial;
	min-height: auto;
	background: transparent;
	color: #003366;
	opacity: 1;
	cursor: pointer;
}

/* .dashboard-page .MuiButtonBase-root:hover {
	color: #337ab7;
	border-color: #eee #eee #ddd;
	border: 1px solid transparent;
	border-bottom-color: transparent;
	border-bottom: 1px solid #ddd;
	background-color: #eee;
} */

.dashboard-page .MuiButtonBase-root.Mui-selected {
	color: #555;
	cursor: default;
	background-color: #fff;
	border: 1px solid #ddd;
	border-bottom-color: transparent;
}

.dashboard-page .MuiTabs-indicator,
.dashboard-page .MuiTouchRipple-root {
	display: none;
}

.dashboard-page .MuiBox-root {
	padding: 0px;
}

/* .formError .MuiFormHelperText-root.Mui-error,
body .MuiFormHelperText-root.Mui-error {
	display: none;
} */

.text-danger {
	color: #bf0000;
	font-size: 13.5px;
	margin-bottom: 10px;
	margin-top: 5px;
	text-align: left;
}

.text-success {
	margin-bottom: 5px;
	margin-top: -5px;
	color: green;
}
.Mui-selected p{
	color: orange !important;
}

@media (max-width: 768px) {
	.popup-model .model-content {
		-webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
		box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
	}
}

@media (max-width: 1250px) {
	.villanova_pager .pagination a {
		padding: 6px 12px;
		margin: 0 3px;
	}
}