.main-banner {
  height: 200px;
  position: relative;
  width: calc(100% - 275px) !important;
  padding: 16px;
}

.edit-main-banner {
  padding-left: 0px;
  padding-top: 0px;
  width: 100% !important;
}

.edit-page-new .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: transparent;
  border-width: 0px;
}

.edit-page-new .MuiOutlinedInput-root:hover fieldset,
.edit-page-news .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #ccc;
}

.edit-page-new .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336;
}

.main-banner-login {
  height: 100vh;
  background: #E9EFF3;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.main-banner-login>div {
  width: 100%;
}

.main-banner span {
  margin: 0;
  /* position: absolute; */
  top: 50%;
  /* left: 50%; */
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* font-size: 20px; */
}

.login {
  /* width: 33.333%; */
  margin: auto;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%);
}

.login h3 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
  color: #0069aa;
}

.login input {
  /* display: inherit;
  background: #f3f5f5;
  height: 37px;
  border-radius: 5px;
  border: 1px solid #f3f5f5;
  width: -webkit-fill-available;
  text-indent: 10px;
  margin-bottom: 10px; */

  height: 20px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 24px);
  font-size: 14px;
  color: #555;
}

.login input::placeholder {
  color: #898888;
}

.login input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.formControl {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.form-group {
  margin-bottom: 15px;
}

.text-center {
  text-align: center;
}

.forgot a {
  color: #0069aa;
  font-size: 14px;
  text-decoration: none;
}

.forgot a:hover {
  text-decoration: underline;
}

.login-button {
  width: 35% !important;
  background: #22bfea !important;
  text-align: center;
  line-height: 1.75;
  border-radius: 5px;
  text-transform: uppercase;
  border: 0px;
  font-family: MuseoSans-500;
  font-weight: normal;
  cursor: pointer;
  color: white;
}

.login label {
  font-family: MuseoSans-500;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
}

.noteError {
  color: #ff0606;
  font-size: 14px;
  font-family: MuseoSans-300;
  font-weight: normal;
  line-height: 1.66;

}

.mb10 {
  margin-bottom: 10px;
}

.noteSuccess {
  color: #008000;
  font-size: 14px;
  font-family: MuseoSans-300;
  font-weight: normal;
  line-height: 1.66;
}

.otp-box input:focus {
  border: 1px solid #80bdff;
}

.otp-button {
  background: #f89b23;
  border: none;
  border-bottom: 2px solid #cb7002;
  color: #fff;
  line-height: normal;
  margin: 0 5px 10px 0;
  padding: 8px 12px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  font-family: "museo-sans", sans-serif;
}

.otp-button:hover {
  background: #e78c1e;
  color: #fff;
}

.button-disable {
  background: #bfbfbf;
  /* box-shadow: 0 4px rgb(0 0 0 / 20%); */
  cursor: not-allowed;
  /* line-height: normal; */
  padding: 8px 12px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 14px;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  font-family: "museo-sans", sans-serif;
}

.login figure {
  margin: 0px;
  background: #016aab;
  padding: 18px 10px;
  text-align: center;
}

.login figure img {
  max-width: 140px;
}

.login-box {
  background: white;
  padding: 20px 20px;
}

.login-box p {
  font-family: MuseoSans-700;
  font-weight: normal;
  margin: 0px 0px 10px;
  font-size: 14px;
  color: #333;
}

.forgot-password h3 {
  text-align: left;
}

.main-banner-login .col-login {
  margin: 0px auto;
}
.container{
 height: 100%;
align-items: center;
margin-left: auto;
margin-right: auto;
}
.container_footer{
  height: auto;
  max-width: 1170px;
  width: 100% !important;
  margin: auto;
  /* flex-grow: 1 */
}
.desktop_footer {
  color: #000000;
  float: left;
  font-size: 14px;
  background: #252525;
  display: flex;
  align-items: center;
  height: auto;
  flex-wrap: wrap;
  padding: 20px 0 15px;
  width: 100%;
  bottom: 0px;
}
.logoDesc{
  display: flex;
  align-items: center;
}
.logoLink{
  
  margin-left: 5px;
}
.copyright{
  padding: 0 15px;

}

.copyright p {
  /* text-align: left; */
  font-size: 16px;
  line-height: 35px;
  color: #fff;
  font-family: MuseoSans-100;
  font-weight: normal;
  margin: 0px;
}
/* .copyright_txt p {
  text-align: center;
} */

.powered-text p {
  /* text-align: right; */
  font-family: MuseoSans-100;
  font-weight: normal;
  display: flex;
  align-items: end;
  justify-content: end;
}

.powered-text p a {
  display: inline-block;
  line-height: 0px;
}

.powered-text p a img {
  max-width: 100px;
  margin-left: 8px;
}

.new_gray_footer {
  border-top: 1px solid #555;
  width: 100%;
}

.terms_links {
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  padding-top: 10px;
}

.terms_links a {
  font-size: 16px;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  text-decoration: underline;
  color: #fff;
}

.terms_links a:hover {
  color: #f89b22;
}

.terms_links span {
  display: inline-block;
  margin: 0px 3px;
}

.school-popup {
  position: relative;
}

.school-popup .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 1);
}

.change-school-popup .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5);
}

.school-popup .MuiDialog-scrollPaper {
  align-items: start;
}

.school-popup .MuiDialog-paperWidthSm {
  width: 80%;
  margin: 30px auto;
}

.school-popup .MuiDialogTitle-root {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.school-popup .MuiDialogTitle-root h2 {
  color: #0069aa;
  line-height: 1.42857143;
  font-size: 18px;
  font-family: MuseoSans-500;
  font-weight: normal;
}

.change-school-popup .MuiDialogTitle-root h2 {
  font-family: MuseoSans-500;
  font-weight: normal;
  font-size: 20px;
  color: #333;
}

.change-school-popup .modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
}

.change-school-popup .modal-header button {
  opacity: .2;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  margin-top: -2px;
  font-family: MuseoSans-700;
  font-weight: normal;
  float: right;
  font-size: 18px;
  font-family: "museo-sans", sans-serif;
}

.change-school-popup .modal-header button:hover {
  opacity: 0.5;
}

.change-school-popup .MuiDialogTitle-root {
  padding: 0;
  border-bottom: 0px solid #e5e5e5;
}

.school-popup .MuiDialogContent-root {
  padding: 15px;
}

.school-popup .MuiDialogContent-root p {
  margin: 0px;
}

.school-popup .MuiDialogContent-root p label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-family: MuseoSans-700;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

.school-popup .MuiSelect-filled {
  width: 100%;
  height: 34px;
  padding: 0px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  line-height: 34px;
}

.school-popup .MuiSelect-filled:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  background-color: #fff;
}

.school-popup .MuiSelect-nativeInput {
  height: 100%;
}

.school-popup .MuiFilledInput-underline:before,
.school-popup .MuiFilledInput-underline:after {
  display: none;
}

.school-popup .MuiSelect-root em,
.school-popup ul.MuiMenu-list li em {
  font-style: normal;
}

.school-popup .MuiSelect-select:focus {
  border-radius: 4px;
}

.model-footer {
  text-align: right;
  border-top: 1px solid #e5e5e5;
  padding: 15px;
}

.model-footer button {
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-family: MuseoSans-300;
  font-weight: normal;
  line-height: 1.42857143;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  min-width: initial;
}

.model-footer button:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.login input.has-error {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
  border: 1px solid #a94442;
}

.desktop_footer.generic_footer{
  background: #252525 none repeat scroll 0 0 !important;
  display: flex;
  align-items: center;
  height:auto;
  flex-wrap: wrap;
     padding: 20px 0px 15px 0px;
}
.generic_footer .copyright_txt p, .univest_footer  .copyright_txt p{
text-align:left !important;
font-family: 'MuseoSans-100';
font-size: 16px !important;
}
.generic_footer .copyright p {
/*padding-top: 0px;*/
}
.terms_links{
color: #fff;
text-align: center;
margin: 0px;
/* padding-top:10px; */
}
.terms_links a{
color: #fff;
text-decoration: underline;
/* font-size: 16px !important; */
}
.terms_links a:hover{
color: #f89b22;
text-decoration: underline;
}
.new_gray_footer{
border-top: 1px solid #555555;
width: 100%;
}
.copy_right_links a{
text-decoration: underline;
}
.copy_right_links a:hover{
color: #f89b22;
text-decoration: underline;
}
.copyright p img {
padding-bottom: 5px;
}
input::placeholder {
  font-weight: 100;
  font-family: MuseoSans-300;
}
.password-check{
  height: auto;
  padding: 50px 0;
}
.password-validation{
  padding-left: 15px;
  margin: 0;
  height: 100%;
  font-size: 14px;
  text-decoration: none;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.password-validation li{
  font-size: 12px;
  display: flex;
  align-items: center;
}
.password-validation img{
  padding-right: 5px;
  width: 12px;
  height: 12px;
}
.password-validation p{
  margin: 0;
}
